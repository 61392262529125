import { createSelector } from "reselect";

export const getLugares = state => state.lugares.lugares;

export const getLugaresForSelect = createSelector(
  [getLugares],
  lugares =>
    lugares.map(lugar => ({
      label: lugar.nombre,
      id: lugar.id,
      value: lugar.id
    }))
);
