import React from "react";
import FormField from "../common/FormField";
import Select from "react-select";
import UploadFile from "../editar-fotos/Adjuntos";

const TextView = ({ accion: { configuracion } }) => {
  return <p>{configuracion}</p>;
};

const EditText = ({ accion, onChange, defaultValue }) => {
  return (
    <input
      className="input"
      type="text"
      defaultValue={defaultValue || ""}
      onChange={event => onChange(accion.campo, event.target.value)}
    />
  );
};

const Checkbox = ({ accion, onChange, defaultValue }) => {
  return (
    <input
      defaultChecked={defaultValue || false}
      className="checkbox"
      type="checkbox"
      onChange={event => onChange(accion.campo, event.target.checked)}
    />
  );
};

const Combo = ({ accion, onChange, defaultValue }) => {
  return (
    <Select
      defaultValue={
        defaultValue
          ? {
              label: defaultValue,
              value: defaultValue
            }
          : null
      }
      options={accion.opciones}
      onChange={event => onChange(accion.campo, event.value)}
    />
  );
};

const Camera = ({ accion, onChange }) => {
  /* Las fotos que ya están guardadas no se deben eliminar al hacer click en "Borrar", porque si el usuario sale sin
    guardar los cambios no se puede recuperar la foto. Se borra recién al guardar. */
  const initialValue = accion.fotos.map(adjunto => ({
    canDelete: false,
    id: adjunto.key,
    foto_url: adjunto.foto_url
  }));
  const CAMERA = true;

  const handleAttach = (fotosArray, adjuntosArray) => {
    onChange(accion.campo, fotosArray, CAMERA, adjuntosArray);
  };

  return (
    <UploadFile
      accion={accion}
      handleAttach={handleAttach}
      initialValue={{ fotos: accion.fotos, adjuntos: initialValue }}
    />
  );
};

const ComponenteDinamico = ({ accion, onChange, defaultValue, admin }) => {
  var componente = null;
  if (accion.componente === "textview") {
    componente = <TextView accion={accion} />;
  }

  if (accion.componente === "edittext") {
    componente = (
      <EditText
        accion={accion}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    );
  }

  if (accion.componente === "checkbox") {
    componente = (
      <label className="checkbox">
        <Checkbox
          accion={accion}
          onChange={onChange}
          defaultValue={defaultValue}
        />
        {accion.descripcion}
      </label>
    );
  }

  if (accion.componente === "combo") {
    componente = (
      <Combo accion={accion} onChange={onChange} defaultValue={defaultValue} />
    );
  }

  if (accion.componente === "camera" && admin) {
    componente = (
      <Camera accion={accion} onChange={onChange} valor={accion.fotos} />
    );
  }
  return <FormField label={accion.descripcion}>{componente}</FormField>;
};
export default ComponenteDinamico;
