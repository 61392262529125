import React from "react";

export const estados = [
  { value: "requerido", label: "requerido", color: "is-warning" },
  { value: "cancelado", label: "cancelado", color: "is-light" },
  { value: "creado", label: "creado", color: "is-primary" },
  { value: "reasignado", label: "reasignado", color: "is-primary" },
  { value: "terminado", label: "terminado", color: "is-link" }
];

const Estado = ({ estado }) => {
  const color = estados.find(item => item.value === estado).color;
  const clases = `tag ${color}`;
  return <span className={clases}>{estado}</span>;
};

export default Estado;
