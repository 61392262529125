import { createAction } from "redux-actions";
import { loadUsuarios } from "../../lib/api";

export const LOAD_USUARIOS = "LOAD_USUARIOS";
export const SET_USUARIO = "SET_USUARIO";

export const fetchUsuarios = createAction(LOAD_USUARIOS, () => {
  return loadUsuarios();
});

export const setCurrentUsuario = createAction(SET_USUARIO);
