import React, { useEffect, useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import EditarOrden from "./guardarOrdenContainer";
import Select from "react-select";
import FormField from "../common/FormField";
import IconButton from "../common/IconButton";
import Estado, { estados } from "./EstadoOrden";
import LugaresSelect from "./LugaresSelect";
import Duracion from "./Duracion";
import { formatoFechaLarga } from "../../lib/fechas";
import { MonthPicker } from "../common/DatePicker";
import Asignar from "./AsignarOperador";
import { store } from 'react-notifications-component';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
const CrearOrden = ({ accion }) => {
  const [modal, setModal] = useState(false);
  const customStyles = {
    content: {
      top: "20%",
      left: "15%",
      right: "15%"
    }
  };

  return (
    <Fragment>
      <button className="button is-primary" onClick={() => setModal(true)}>
        {accion}
      </button>
      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <EditarOrden setModal={setModal} accion={accion} />
      </Modal>
    </Fragment>
  );
};

const handle = () => {
  store.addNotification({
    title: "Atención",
    message: "Es necesario seleccionar un periodo",
    type: "warning",
    container:"top-right",
    insert: "top",
    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
animationOut: ["animate__animated animate__fadeOut"],

    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true,
      pauseOnHover: true
    },
    slidingExit: {
      duration: 800,
      timingFunction: 'ease-out',
      delay: 0
    },
    touchSlidingExit: {
      swipe: {
        duration: 400,
        timingFunction: 'ease-out',
        delay: 0,
      },
      fade: {
        duration: 400,
        timingFunction: 'ease-out',
        delay: 0
      }
    },
    width: 700
  })
};


const VerExcel = ({ excelOrdenes, filtros }) => {
  return (
    <div className="control">
      <IconButton icon="file-excel-o" onClick={() => {
        if (filtros && filtros.periodo){
          excelOrdenes(filtros);
        }else{
          handle();
          }
          }}>
        Excel
      </IconButton>
      
    </div>
  );
};

const Index = ({
  getOrdenes,
  updateEstado,
  loading,
  ordenes,
  usuarios,
  trabajos,
  currentPage,
  totalPages,
  excelOrdenes,
  permisos
}) => {
  const [filtros, setFiltros] = useState({});

  useEffect(() => {
    getOrdenes(filtros);
  }, []);

  const filtrar = nuevoFiltro => {
    setFiltros(nuevoFiltro);
    getOrdenes(nuevoFiltro);
  };

  
  
  const atras = () => {
    getOrdenes(filtros, currentPage - 1);
  };

  const adelante = () => {
    getOrdenes(filtros, currentPage + 1);
  };

  const cancelar = async ordenId => {
    if (confirm("¿Está seguro de que desea cancelar esta orden?")) {
      await updateEstado({ id: ordenId, estado: "cancelado" });
    }
  };

  return (
    <div>
      <ReactNotification />
      <div className="shadow-lg p-4 mb-4">
        <h3 className="title text-lg mb-2">Filtros</h3>
        <div className="columns">
          <div className="column">
            <FormField label="Periodo">
              <MonthPicker
                value={filtros.periodo}
                onChange={date => filtrar({ ...filtros, periodo: date })}
              />
            </FormField>
          </div>
          <div className="column">
            <FormField label="Número de Orden">
              <input
                className="input"
                type="text"
                placeholder="Buscar..."
                onChange={e =>
                  filtrar({ ...filtros, nro_orden: e.target.value })
                }
              />
            </FormField>
          </div>
          <div className="column">
            <FormField label="Estado de la Orden">
              <Select
                options={estados}
                onChange={e =>
                  e
                    ? filtrar({ ...filtros, estado: e.value })
                    : filtrar({ ...filtros, estado: e })
                }
                placeholder="Buscar..."
                isClearable
              />
            </FormField>
          </div>
          <div className="column">
            <FormField label="Operador Asignado">
              <Select
                options={usuarios}
                onChange={e =>
                  e
                    ? filtrar({ ...filtros, usuario_id: e.id })
                    : filtrar({ ...filtros, usuario_id: e })
                }
                placeholder="Buscar..."
                isClearable
              />
            </FormField>
          </div>
          <div className="column">
            <FormField label="Lugar">
              <LugaresSelect
                onChange={e =>
                  e
                    ? filtrar({ ...filtros, lugar_id: e.id })
                    : filtrar({ ...filtros, lugar_id: e })
                }
              />
            </FormField>
          </div>
          <div className="column">
            <FormField label="Trabajo">
              <Select
                options={trabajos}
                onChange={e =>
                  e
                    ? filtrar({ ...filtros, trabajo_id: e.id })
                    : filtrar({ ...filtros, trabajo_id: e })
                }
                placeholder="Buscar..."
                isClearable
              />
            </FormField>
          </div>
        </div>
      </div>

      {permisos && (
        <div className="buttons">
          {permisos.crear_ordenes && <CrearOrden accion="Agregar" />}
          {permisos.requerir_ordenes && <CrearOrden accion="Requerir" />}
          {permisos.ver_excel && (
            <VerExcel filtros={filtros} excelOrdenes={excelOrdenes} />
          )}
        </div>
      )}

      <table className="table is-fullwidth">
        <thead>
          <tr className="tr">
            <th>Número</th>
            <th>Lugar</th>
            <th>Trabajo</th>
            <th>Estado Actual</th>
            <th>Tiempo</th>
            <th>Operador Asignado</th>
            <th />
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>Cargando...</td>
            </tr>
          </tbody>
        ) : (
          <tbody className="tbody">
            {ordenes.map(orden => (
              <tr key={orden.numero}>
                <td>{orden.numero}</td>
                <td>{orden.lugar.nombre}</td>
                <td>{orden.trabajo.nombre}</td>
                <td className="has-text-centered">
                  <Estado estado={orden.estado} />
                </td>
                <td className="has-text-centered">
                  <span className="is-size-7">
                    {formatoFechaLarga(orden.finished_at || orden.created_at)}
                  </span>
                  <br />
                  {!orden.finished_at && <Duracion orden={orden} />}
                </td>
                <td>{orden.usuario ? orden.usuario.nombre : "No asignado"}</td>
                <td>
                  <div className="field is-grouped">
                    <p className="control">
                      <NavLink
                        to={`/ver_ordenes/${orden.id}`}
                        className="button"
                      >
                        Ver
                      </NavLink>
                    </p>
                    {((orden.estado === "creado" &&
                      permisos.cancelar_ordenes) ||
                      (orden.estado === "requerido" &&
                        permisos.requerir_ordenes)) && (
                      <p className="control">
                        <button
                          className="button is-danger"
                          onClick={() => cancelar(orden.id)}
                        >
                          Cancelar
                        </button>
                      </p>
                    )}
                    {orden.estado === "requerido" &&
                      permisos.asignar_operador && <Asignar orden={orden} />}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <div className="buttons">
        <button className="button" onClick={atras} disabled={currentPage == 1}>
          Anterior
        </button>
        <button
          className="button"
          onClick={adelante}
          disabled={currentPage >= totalPages}
        >
          Siguiente
        </button>
        {totalPages > 0 && (
          <div>
            Página {currentPage} de {totalPages}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
