import React, { useState, Fragment } from "react";
import Ubicacion from "./ubicacion.jsx";
import GoogleMapReact from "google-map-react";
import useGeolocation from "../../lib/useGeolocation";
import Marker from "./Marker";
import { ZoomIn } from "@material-ui/icons";

const gmapKey = document.head.querySelector("[name~=gmap_key][content]")
  .content;

export default function Index({ usuarios, fetchUsuarios }) {
  const [gMap, setMap] = useState(null);
  const [gMaps, setMaps] = useState(null);
  const geo = useGeolocation();

  const defaultProps = {
    center: {
      lat: geo.coordinates.lat || 59.22,
      lng: geo.coordinates.lng || 30.3
    },
    zoom: 11
  };

  const handleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  const zoom = usuario => {
    const latlng = new gMaps.LatLng(usuario.last_lat, usuario.last_lng);
    gMap.setZoom(9);
    gMap.panTo(latlng);
    setTimeout(() => gMap.setZoom(16), 1000);
  };

  const zoomAll = () => {
    const _bounds = new gMaps.LatLngBounds();

    usuarios.forEach(marker => {
      const position = new gMaps.LatLng(marker.last_lat, marker.last_lng);
      _bounds.extend(position);
    });

    gMap.fitBounds(_bounds);
  };

  return (
    <Fragment>
      <div className="columns">
        <div className="column is-three-quarters">
          <h1 className="title">Tracking</h1>
          <div style={{ height: "90vh", width: "100%" }}>
            {!geo.loading && (
              <GoogleMapReact
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
                bootstrapURLKeys={{
                  key: gmapKey
                }}
              >
                {usuarios.map(usuario => (
                  <Marker
                    key={usuario.id}
                    lat={usuario.last_lat}
                    lng={usuario.last_lng}
                    usuario={usuario}
                  />
                ))}
              </GoogleMapReact>
            )}
          </div>
        </div>
        <div className="column">
          <div className="flex items-center">
            <button className="button" onClick={zoomAll}>
              <ZoomIn />
            </button>
            <span className="ml-2 text-lg font-bold">
              {usuarios.length} Usuarios
            </span>
          </div>
          {!gMap && "Cargando..."}
          {gMap && (
            <div className="p-1" style={{ height: "90vh", overflow: "auto" }}>
              {usuarios.map(usuario => (
                <Ubicacion
                  key={usuario.id}
                  usuario={usuario}
                  onChange={() => fetchUsuarios()}
                  onZoomClick={zoom}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
