/* eslint-disable no-shadow */
import React, { useState, Fragment } from "react";
import Select from "react-select";
import ComponenteDinamico from "./ComponenteDinamico";
import classNames from "classnames";
import FormField from "../common/FormField";
import LugaresSelect from "./LugaresSelect";
import { DateTimePicker } from "../common/DatePicker";

const GuardarOrden = ({
  agregarOrden,
  updateOrden,
  usuarios,
  trabajos,
  saving,
  admin,
  permisos,
  setModal,
  ordenActual,
  getOrdenes,
  id,
  accion
}) => {
  const estado = accion === "Agregar" ? "creado" : "requerido";
  const [orden, setOrden] = useState(ordenActual || { respuestas: [], estado });
  const [trabajo, setTrabajo] = useState(null);

  const Guardar = async e => {
    e.preventDefault();
    const payload = { ...orden };
    payload.respuestas = JSON.stringify(payload.respuestas);
    if (admin) {
      await updateOrden({
        id: id,
        lugar_id: payload.lugar_id,
        usuario_id: payload.usuario_id,
        direccion: payload.direccion,
        referencia: payload.referencia,
        created_at: payload.created_at,
        finished_at: payload.finished_at,
        respuestas: payload.respuestas,
        adjuntos: payload.adjuntos || []
      });
      alert("Datos guardados correctamente");
      location.reload();
    } else {
      await agregarOrden(payload);
      setOrden({ respuestas: [] });
      await getOrdenes();
      setModal(false);
    }
  };

  let acciones = [];
  if (ordenActual) {
    acciones = ordenActual.respuestas;
  } else {
    acciones = trabajo
      ? trabajo.acciones.filter(
          accion =>
            admin === true ||
            accion.visibilidad === "ambos" ||
            accion.visibilidad === "web"
        )
      : [];
    if (accion === "Requerir") {
      acciones = acciones
        ? acciones.filter(accion => accion.requerimiento === true)
        : [];
    }
  }
  const elegirTrabajo = elegido => {
    setTrabajo(elegido);
    const nuevaOrden = {
      ...orden,
      respuestas: elegido.acciones.map(accion => {
        return {
          campo: accion.campo,
          descripcion: accion.descripcion,
          visibilidad: accion.visibilidad,
          tipo_dato: accion.tipo_dato,
          opciones: accion.opciones,
          configuracion: accion.configuracion,
          requerido: accion.requerido,
          solo_lectura: accion.solo_lectura,
          componente: accion.componente,
          orden: accion.orden
        };
      })
    };
    setOrden({ ...nuevaOrden, trabajo_id: elegido.id });
  };

  const guardarRespuesta = (campo, valor, camera, adjuntos) => {
    const respuestaIdx = orden.respuestas.findIndex(
      resp => resp.campo === campo
    );
    let respuesta = {};
    /* Si la respuesta es del tipo "camera", se actualiza el campo "fotos"
    en lugar del campo "valor", como en los demás componentes. */
    if (camera) {
      respuesta = { ...orden.respuestas[respuestaIdx], fotos: valor };
    } else {
      respuesta = { ...orden.respuestas[respuestaIdx], valor };
    }

    const respuestas = [
      ...orden.respuestas.slice(0, respuestaIdx),
      { ...respuesta },
      ...orden.respuestas.slice(respuestaIdx + 1)
    ];
    setOrden({ ...orden, respuestas, adjuntos });
  };

  const invalid = permisos.crear_ordenes
    ? !orden.trabajo_id || !orden.usuario_id || !orden.lugar_id
    : !orden.trabajo_id || !orden.lugar_id;

  const guardarClass = classNames({
    button: true,
    "is-primary": true,
    "is-loading": saving
  });

  return (
    <div>
      {!admin && (
        <Fragment>
          <h1 className="title">Crear Orden de Trabajo</h1>
          <hr className="navbar-divider" />
        </Fragment>
      )}
      <form style={{ padding: "15px" }}>
        <div className="columns">
          <div className="column">
            <FormField label="Lugar">
              <LugaresSelect
                defaultValue={
                  ordenActual
                    ? {
                        label: ordenActual.lugar.nombre,
                        value: ordenActual.lugar.id
                      }
                    : null
                }
                onChange={e =>
                  e
                    ? setOrden({ ...orden, lugar_id: e.id })
                    : setOrden({ ...orden, lugar_id: e })
                }
              />
            </FormField>
          </div>
          {permisos.crear_ordenes && (
            <div className="column">
              <FormField label="Operador">
                <Select
                  options={usuarios}
                  onChange={e => setOrden({ ...orden, usuario_id: e.id })}
                  defaultValue={
                    ordenActual && ordenActual.usuario
                      ? {
                          label: ordenActual.usuario.nombre || null,
                          value: ordenActual.usuario.id || null
                        }
                      : null
                  }
                />
              </FormField>
            </div>
          )}
        </div>
        <FormField label="Trabajo">
          {ordenActual ? (
            ordenActual.trabajo.nombre
          ) : (
            <Select options={trabajos} onChange={e => elegirTrabajo(e)} />
          )}
        </FormField>
        {acciones.map((accion, key) => (
          <ComponenteDinamico
            key={key}
            accion={accion}
            defaultValue={accion.valor}
            onChange={guardarRespuesta}
            admin={admin}
          />
        ))}
        <div className="columns">
          <div className="column">
            <FormField label="Dirección">
              <input
                type="text"
                className="input"
                onChange={e =>
                  setOrden({ ...orden, direccion: e.target.value })
                }
                defaultValue={ordenActual ? ordenActual.direccion : null}
              />
            </FormField>
          </div>
          <div className="column">
            <FormField label="Referencia">
              <input
                type="text"
                className="input"
                onChange={e =>
                  setOrden({ ...orden, referencia: e.target.value })
                }
                defaultValue={ordenActual ? ordenActual.referencia : null}
              />
            </FormField>
          </div>
        </div>
        {admin && (
          <div className="columns">
            <div className="column">
              <FormField label="Fecha Creación">
                <DateTimePicker
                  value={orden.created_at || ordenActual.created_at || null}
                  onChange={date => setOrden({ ...orden, created_at: date })}
                />
              </FormField>
            </div>
            <div className="column">
              <FormField label="Fecha Terminado">
                <DateTimePicker
                  value={orden.finished_at || ordenActual.finished_at || null}
                  onChange={date => setOrden({ ...orden, finished_at: date })}
                />
              </FormField>
            </div>
          </div>
        )}
        <div className="field is-grouped">
          <div className="control">
            <button
              className={guardarClass}
              onClick={Guardar}
              disabled={saving || invalid}
            >
              {saving ? "Guardando..." : "Guardar"}
            </button>
            {setModal && (
              <button className="button" onClick={() => setModal(false)}>
                Cerrar
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default GuardarOrden;
