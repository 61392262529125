const queryCSRFToken = () => {
  const metaTag = document.querySelector("meta[name=csrf-token]");
  return metaTag ? metaTag.content : null;
};

const csrfToken = () => {
  let token;

  return () => {
    if (token === undefined) {
      token = queryCSRFToken();
    }

    return token;
  };
};

export default csrfToken();
