import React from "react";
import missing from "images/missing.png";
import Tooltip from "@material-ui/core/Tooltip";

const Marker = ({ usuario }) => {
  const image = usuario.avatar_url.match("/missing")
    ? missing
    : usuario.avatar_url;
  return (
    <div>
      <Tooltip title={usuario.nombre}>
        <img src={image} alt={usuario.nombre} style={{ cursor: "pointer" }} />
      </Tooltip>
    </div>
  );
};

export default Marker;
