import {
  ORDENES_LOADING,
  ORDENES_LOADED,
  ORDEN_LOADING,
  ORDEN_LOADED,
  ORDENES_CREATING,
  ORDENES_CREATED,
  ORDEN_UPDATED,
  ESTADO_UPDATED
} from "../actions/ordenes";
import update from "immutability-helper";

const INITIAL_STATE = {
  orden: null,
  datos: [],
  loading: false,
  saving: false,
  meta: {},
  page: 1
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ORDENES_CREATING:
      return { ...state, saving: true };
    case ORDENES_CREATED:
      return {
        ...state,
        saving: false
      };
    case ORDENES_LOADING:
      return { ...state, loading: true };
    case ORDENES_LOADED:
      return {
        ...state,
        loading: false,
        orden: null, // resetea la orden actual
        datos: action.payload.data,
        meta: action.payload.meta,
        page: action.payload.page
      };
    case ORDEN_LOADING:
      return { ...state, loading: true };
    case ORDEN_LOADED:
      return {
        ...state,
        loading: false,
        orden: action.payload.data
      };
    case ESTADO_UPDATED:
      const { id, estado } = action.payload.data;
      const index = state.datos.findIndex(dato => dato.id === id);
      const nuevasOrdenes = update(state.datos, {
        [index]: { estado: { $set: estado } }
      });

      return {
        ...state,
        datos: nuevasOrdenes
      };
    case ORDEN_UPDATED:
      return {
        ...state,
        orden: action.payload.data
      };
    default:
      return state;
  }
}
