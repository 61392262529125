import { LOAD_USUARIOS, SET_USUARIO } from "../actions/data";

const INITIAL_STATE = {
  usuarios: [],
  currentUsuario: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USUARIO:
      return { ...state, currentUsuario: action.payload };
    case LOAD_USUARIOS:
      return { ...state, usuarios: action.payload.data };
    default:
      return state;
  }
}
