import React, { useEffect } from "react";
import { formatoFechaLarga } from "../../lib/fechas";
import Estado from "../ordenes/EstadoOrden";
import Camera from "./Camera";
import Duracion from "../ordenes/Duracion";

const FormField = props => (
  <div className="my-1">
    {props.label && (
      <label htmlFor={props.label.toLowerCase()} className="mb-1 font-bold">
        {props.label}
      </label>
    )}
    <div className="rounded-lg bg-gray-200 p-2" style={{ minHeight: 40 }}>
      {props.children}
    </div>
  </div>
);

const Checkbox = ({ respuesta }) => {
  return (
    <FormField label={respuesta.descripcion}>
      {respuesta.valor === true ? "Sí" : "-"}
    </FormField>
  );
};

const Edittext = ({ respuesta }) => {
  return <FormField label={respuesta.descripcion}>{respuesta.valor}</FormField>;
};

const Index = ({ match, history, getOrden, orden }) => {
  let { id } = match.params;

  useEffect(() => {
    getOrden(id);
  }, []);

  if (!orden) {
    return <p>Cargando...</p>;
  }

  orden.respuestas.sort((a, b) => a.orden - b.orden);

  const renderRespuesta = (respuesta, key, estado) => {
    const respuestaVacia =
      respuesta.valor === "" && respuesta.fotos && respuesta.fotos.length === 0;

    if (
      respuestaVacia &&
      respuesta.componente !== "textview" &&
      estado === "terminado"
    )
      return null;

    switch (respuesta.componente) {
      case "edittext":
        return <Edittext respuesta={respuesta} key={key} />;
      case "checkbox":
        return <Checkbox respuesta={respuesta} key={key} />;
      case "camera":
        return <Camera respuesta={respuesta} key={key} />;
      case "combo":
        return <Edittext respuesta={respuesta} key={key} />;
      case "textview":
        return (
          <div className="py-1 my-2 border-blue-500 border-b-2">
            <h3 className="">{respuesta.descripcion}</h3>
          </div>
        );
      default:
        return null;
    }
  };

  const keys = Object.keys(orden.respuestas);

  return (
    <div className="shadow-inner p-4">
      <button className="button is-primary" onClick={() => history.goBack()}>
        Volver
      </button>
      <div className="columns">
        <div className="column">
          <FormField label="Número de Orden">{orden.numero}</FormField>
          <FormField label="Estado Actual">
            <Estado estado={orden.estado} />
          </FormField>
          <FormField label="Referencia">{orden.referencia}</FormField>
        </div>
        <div className="column">
          <FormField label="Fecha Creación">
            {formatoFechaLarga(orden.created_at)}
          </FormField>
          <FormField label="Trabajo">{orden.trabajo.nombre}</FormField>
          <FormField label="Dirección">{orden.direccion}</FormField>
        </div>
        <div className="column">
          <FormField label="Fecha Actualización">
            {formatoFechaLarga(orden.updated_at)}
          </FormField>
          <FormField label="Lugar">{orden.lugar.nombre}</FormField>
        </div>
        <div className="column">
          <FormField label="Fecha Terminado">
            {formatoFechaLarga(orden.finished_at)} <Duracion orden={orden} />
          </FormField>
          <FormField label="Operador Asignado">
            {orden.usuario ? orden.usuario.nombre : "No asignado"}
          </FormField>
        </div>
      </div>
      <div className="container">
        <h1 className="bg-blue-600 rounded-lg p-2 text-white text-2xl mb-4">
          Formulario: {orden.trabajo.nombre}
        </h1>
        {keys.map(key =>
          renderRespuesta(orden.respuestas[key], key, orden.estado)
        )}
      </div>
      <button className="button is-primary" onClick={() => history.goBack()}>
        Volver
      </button>
    </div>
  );
};

export default Index;
