import { connect } from "react-redux";
import { fetchUsuarios } from "../../store/actions/data";
import Mapas from "./Index";
import { getUsuarios as getUsuariosConGeo } from "../../store/selectors/usuarios";

const mapStateToProps = state => {
  return {
    usuarios: getUsuariosConGeo(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUsuarios: () => dispatch(fetchUsuarios())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mapas);
