import { connect } from "react-redux";
import GuardarOrden from "./guardarOrden";
import { getLugaresForSelect } from "../../store/selectors/lugares";
import { getTrabajosForSelect } from "../../store/selectors/trabajos";
import { getUsuariosForSelect } from "../../store/selectors/usuarios";
import {
  agregarOrden,
  updateOrden,
  fetchOrdenes
} from "../../store/actions/ordenes";

const mapStateToProps = state => {
  return {
    lugares: getLugaresForSelect(state),
    trabajos: getTrabajosForSelect(state),
    usuarios: getUsuariosForSelect(state),
    permisos: state.data.currentUsuario.permisos,
    saving: state.ordenes.saving,
    ordenActual: state.ordenes.orden
  };
};

const mapDispatchToProps = dispatch => {
  return {
    agregarOrden: orden => dispatch(agregarOrden(orden)),
    updateOrden: cambios => dispatch(updateOrden(cambios)),
    getOrdenes: () => dispatch(fetchOrdenes())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuardarOrden);
