import React, { useEffect } from "react";
import { connect } from "react-redux";
import EditarOrden from "./guardarOrdenContainer";
import { fetchOrden } from "../../store/actions/ordenes";

const EditarOrdenScreen = ({ match, orden, getOrden }) => {
  let { id } = match.params;

  useEffect(() => {
    getOrden(id);
  }, []);

  if (!orden) {
    return <p>Cargando...</p>;
  }

  return (
    <div>
      <EditarOrden id={id} admin={true} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    orden: state.ordenes.orden
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrden: id => dispatch(fetchOrden(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarOrdenScreen);
