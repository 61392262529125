import { connect } from "react-redux";
import Ordenes from "./Index";
import { getUsuariosForSelect } from "../../store/selectors/usuarios";
import { getTrabajosForSelect } from "../../store/selectors/trabajos";
import {
  fetchOrdenes,
  updateEstado,
  excelOrdenes
} from "../../store/actions/ordenes";

const mapStateToProps = state => {
  return {
    usuarios: getUsuariosForSelect(state),
    trabajos: getTrabajosForSelect(state),
    permisos: state.data.currentUsuario.permisos,
    loading: state.ordenes.loading,
    ordenes: state.ordenes.datos,
    currentPage: state.ordenes.page,
    totalPages: state.ordenes.meta.page_count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrdenes: (filtros, page) => dispatch(fetchOrdenes(filtros, page)),
    updateEstado: cambios => dispatch(updateEstado(cambios)),
    excelOrdenes: query => dispatch(excelOrdenes(query))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ordenes);
