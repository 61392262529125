import { connect } from "react-redux";
import VerOrdenes from "./Index";
import { fetchOrden } from "../../store/actions/ordenes";

const mapStateToProps = state => {
  return {
    orden: state.ordenes.orden
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrden: id => dispatch(fetchOrden(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerOrdenes);
