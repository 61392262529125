import {
  formatDistanceToNow,
  formatDistanceStrict,
  toDate,
  parseISO,
  format,
  differenceInHours
} from "date-fns";
import es from "date-fns/locale/es";

export const difHoras = (fecha, cuando) => {
  let otro = null;
  if (cuando) {
    otro = toDate(parseISO(cuando));
  } else {
    otro = new Date();
  }
  const dateObj = toDate(parseISO(fecha));
  return differenceInHours(otro, dateObj);
};

export const difHorasConFormato = (fecha, cuando) => {
  let otro = null;
  if (cuando) {
    otro = toDate(parseISO(cuando));
  } else {
    otro = new Date();
  }
  const dateObj = toDate(parseISO(fecha));
  if (difHoras(fecha, cuando) > 2) {
    return formatDistanceStrict(otro, dateObj, { locale: es });
  } else {
    return formatDistanceStrict(otro, dateObj, { locale: es, unit: "minute" });
  }
};

export const timeAgo = fecha => {
  if (fecha === null) {
    return "";
  }
  const dateObj = toDate(parseISO(fecha));
  return formatDistanceToNow(dateObj, { locale: es });
};

export const formatoFechaLarga = fecha => {
  if (!fecha) return "";
  const dateObj = toDate(parseISO(fecha));
  return format(dateObj, "dd/MM/yyyy HH:mm", { locale: es });
};
