import { createAction } from "redux-actions";
import {
  loadOrdenes,
  getOrden,
  crearOrden,
  editarOrden,
  excelOrdenes as apiExcelOrdenes,
  purgeOrdenAttachment
} from "../../lib/api";

export const ORDENES_LOADING = "ORDENES_LOADING";
export const ORDENES_LOADED = "ORDENES_LOADED";
export const ORDEN_LOADING = "ORDEN_LOADING";
export const ORDEN_LOADED = "ORDEN_LOADED";
export const ORDENES_CREATING = "ORDENES_CREATING";
export const ORDENES_CREATED = "ORDENES_CREATED";
export const ORDEN_UPDATED = "ORDEN_UPDATED";
export const ESTADO_UPDATED = "ESTADO_UPDATED";
const EXCEL_ORDENES = "EXCEL_ORDENES";

const setOrdenesLoading = createAction("ORDENES_LOADING");
const setOrdenesData = createAction("ORDENES_LOADED");
const setOrdenLoading = createAction("ORDEN_LOADING");
const setOrdenData = createAction("ORDEN_LOADED");
const creatingOrden = createAction("ORDENES_CREATING");
const ordenCreated = createAction("ORDENES_CREATED");
const ordenUpdated = createAction("ORDEN_UPDATED");
const estadoUpdated = createAction("ESTADO_UPDATED");

export function fetchOrdenes(filtros, page = 1) {
  return dispatch => {
    dispatch(setOrdenesLoading());
    loadOrdenes(filtros, page).then(response => {
      let { data, meta } = response;
      dispatch(setOrdenesData({ data, meta, page }));
    });
  };
}

export async function fetchOrden(id) {
  return async dispatch => {
    dispatch(setOrdenLoading());
    let response = await getOrden(id);
    let { data } = response;

    dispatch(setOrdenData({ data }));
  };
}

export async function agregarOrden(orden) {
  return async dispatch => {
    dispatch(creatingOrden());
    let response = await crearOrden(orden);
    let { data } = response;

    dispatch(ordenCreated({ data }));
  };
}

export async function updateEstado(cambios) {
  return async dispatch => {
    let response = await editarOrden(cambios);
    let { data } = response;

    dispatch(estadoUpdated({ data }));
  };
}

export async function updateOrden(cambios) {
  let adjuntos = cambios.adjuntos;

  /* Si el adjunto tiene la propiedad "className", significa que ya se había guardado anteriormente y 
  se dio "Borrar" al editar la orden, recién ahora se puede eliminar el attachment y el archivo. */
  adjuntos.map(adjunto =>
    adjunto.className ? purgeOrdenAttachment(adjunto.id) : adjunto
  );

  /* Si el adjunto tiene la propiedad canDelete: true, significa que aún no se guardó y no existe attachment,
  se filtra para guardar solo los adjuntos que no tienen attachment y que no aparezca error por ser duplicado. */
  adjuntos = adjuntos.filter(adjunto => adjunto.canDelete);
  return async dispatch => {
    await editarOrden({ ...cambios, adjuntos: adjuntos });
    let response = await getOrden(cambios.id);
    let { data } = response;

    dispatch(ordenUpdated({ data }));
  };
}

export const excelOrdenes = createAction(EXCEL_ORDENES, query => {
  return apiExcelOrdenes(query);
});
