import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { logout as apiLogout } from "../../lib/api";
import logo from "images/logo-dotech-software.png";

const Hamburguer = ({ onClick, open }) => {
  var classes = "navbar-burger";
  if (open) {
    classes += " is-active";
  }
  return (
    <a
      role="button"
      className={classes}
      aria-label="menu"
      aria-expanded="false"
      onClick={onClick}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  );
};

const Dropdown = ({ urls, usuario }) => {
  const logout = async e => {
    e.preventDefault();
    await apiLogout();
    window.location = "/";
  };

  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <a className="navbar-link">{usuario.display_name}</a>

      <div className="navbar-dropdown">
        {usuario.permisos.ver_admin && (
          <Fragment>
            <a className="navbar-item" href={urls.admin}>
              Admin
            </a>
            <hr className="navbar-divider" />
          </Fragment>
        )}
        <a className="navbar-item" onClick={logout}>
          Cerrar sesión
        </a>
      </div>
    </div>
  );
};

const Header = ({ usuario, urls }) => {
  const [open, setOpen] = useState(false);
  var classes = "navbar-menu";
  if (open) {
    classes = "navbar-menu is-active";
  }
  return (
    <nav
      className="navbar dotech-navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <NavLink className="navbar-item" to="/">
          <img src={usuario.logo_url || logo} />
        </NavLink>
        <Hamburguer open={open} onClick={() => setOpen(!open)} />
      </div>
      <div className={classes}>
        <div className="navbar-start">
          <NavLink className="navbar-item" to="/">
            Inicio
          </NavLink>
          <NavLink
            className="navbar-item"
            activeClassName="is-active"
            to="/asignar_ordenes"
          >
            Ordenes de Trabajo
          </NavLink>
          {usuario.reporte_url && (
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/reportes"
            >
              Reportes
            </NavLink>
          )}
          {usuario.permisos.ver_mapas && (
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/mapas/ubicaciones"
            >
              Mapas
            </NavLink>
          )}
        </div>
        <div className="navbar-end">
          <Dropdown urls={urls} usuario={usuario} />
        </div>
      </div>
    </nav>
  );
};

export default Header;
