import React, { useEffect } from "react";
import { Provider, connect } from "react-redux";
import configureStore from "./store/configureStore.js";
const store = configureStore();

import MapasUbicaciones from "./components/mapas/IndexContainer";
import AsignarOrdenes from "./components/ordenes/IndexContainer";
import VerOrdenes from "./components/ver_ordenes/IndexContainer";
import EditarOrden from "./components/ordenes/EditarOrden";
import Home from "./components/home/IndexContainer";

import { fetchTrabajos } from "./store/actions/trabajos";
import { fetchUsuarios, setCurrentUsuario } from "./store/actions/data";

import Header from "./components/common/Header";

import { BrowserRouter as Router, Route } from "react-router-dom";

const isFromAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const admin = urlParams.get("admin");
  return admin === "true";
};

const App = ({ getTrabajos, getUsuarios, urls, usuario, setUsuario }) => {
  useEffect(() => {
    getTrabajos();
    getUsuarios();
    setUsuario(usuario);
  }, []);

  return (
    <Router basename="/dashboard">
      <>
        {!isFromAdmin() && <Header urls={urls} usuario={usuario} />}
        <div className="container is-fullhd">
          {usuario.permisos.ver_mapas && <Route path="/mapas/ubicaciones" component={MapasUbicaciones} />}
          <Route path="/asignar_ordenes" component={AsignarOrdenes} />
          <Route path="/reportes" component={Home} />
          <Route path="/ordenes/:id/editar" component={EditarOrden} />
          <Route path="/ver_ordenes/:id" component={VerOrdenes} />
          <Route exact path="/" component={AsignarOrdenes} />
        </div>
      </>
    </Router>
  );
};

export const MainApp = props => {
  return (
    <Provider store={store}>
      <ContainerApp {...props} />
    </Provider>
  );
};

const mapStateToProps = _state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    getTrabajos: () => dispatch(fetchTrabajos()),
    setUsuario: usuario => dispatch(setCurrentUsuario(usuario)),
    getUsuarios: () => dispatch(fetchUsuarios())
  };
};

const ContainerApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default MainApp;
