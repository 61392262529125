import { LOAD_LUGARES } from "../actions/lugares";

const INITIAL_STATE = {
  lugares: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_LUGARES:
      return { ...state, lugares: action.payload.data };
    default:
      return state;
  }
}
