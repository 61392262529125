/* eslint-disable react/jsx-no-bind */
import { DirectUploadProvider } from "react-activestorage-provider";
import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { deleteOrdenAttach, getURL } from "../../lib/api";
import Camera from "./Camera";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

const Adjuntos = ({ accion, handleAttach, initialValue }) => {
  // El array de fotos solo tiene el key de la imagen
  const [adjuntos, setAdjuntos] = useState([]);
  const [fotos, setFotos] = useState([]);

  useEffect(() => {
    setFotos(initialValue.fotos);
    setAdjuntos(initialValue.adjuntos);
    handleAttach(initialValue.fotos, initialValue.adjuntos);
  }, []);

  const handleAttachment = async signedId => {
    const { url } = await getURL(signedId[0]);

    // Fotos
    const fotosCopy = fotos.slice();
    fotosCopy[fotosCopy.length - 1].key = signedId[0];
    setFotos(fotosCopy);

    // Adjuntos
    const adjuntosCopy = adjuntos.slice();
    adjuntosCopy[adjuntosCopy.length - 1].id = signedId[0];
    adjuntosCopy[adjuntosCopy.length - 1].foto_url = url;
    setAdjuntos(adjuntosCopy);

    handleAttach(fotosCopy, adjuntosCopy);
  };

  const updateUploadedFileList = async upload => {
    let attach = { id: upload.id, canDelete: true };
    let foto = { key: upload.id };
    setFotos(oldArray => [...oldArray, foto]);
    setAdjuntos(oldArray => [...oldArray, attach]);
  };

  const uploadOptions = {
    onBeforeBlobRequest: updateUploadedFileList,
    multiple: true
  };

  const deleteAttachFromBackend = async fileId => {
    try {
      const resp = await deleteOrdenAttach(fileId);
      console.log("res delete", resp);
    } catch (err) {
      console.log("err delete", err);
    }
  };

  const deleteAttach = file => {
    const filteredFotos = fotos.filter(foto => foto.key !== file.id);
    setFotos(filteredFotos);

    /* Si se puede borrar la foto (aún no se guardó como attachment de la orden), se borra directamente;
    si no, solo se esconde agregándole className: "hidden" (display: none). */
    if (file.canDelete) {
      const filteredFiles = adjuntos.filter(adjunto => adjunto.id !== file.id);
      deleteAttachFromBackend(file.id);
      setAdjuntos(filteredFiles);
      handleAttach(filteredFotos, filteredFiles);
    } else {
      const hiddenFile = adjuntos.map(adjunto =>
        adjunto.id === file.id ? { ...adjunto, className: "hidden" } : adjunto
      );
      setAdjuntos(hiddenFile);
      handleAttach(filteredFotos, hiddenFile);
    }
  };

  return (
    <div>
      <DirectUploadProvider
        {...uploadOptions}
        onSuccess={handleAttachment}
        render={({ handleUpload, uploads, ready }) => (
          <div>
            <Grid item xs={12} md={6}>
              <div>
                <List>
                  <ListItem>
                    <input
                      disabled={!ready}
                      onChange={e => handleUpload(e.currentTarget.files)}
                      className="hidden"
                      id={accion.campo}
                      type="file"
                      accept="image/*"
                    />
                    <label htmlFor={accion.campo}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Seleccionar foto
                      </Button>
                    </label>
                  </ListItem>
                  {adjuntos.map((upload, index) => (
                    <div key={index} className="inline-flex">
                      <ListItem className={upload.className}>
                        <Camera
                          foto={upload}
                          key={index}
                          deleteAttach={deleteAttach}
                          adjuntos={adjuntos}
                        />
                        <ListItemSecondaryAction className={upload.className}>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => deleteAttach(upload)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  ))}
                </List>
              </div>
            </Grid>

            {uploads.map(upload => {
              switch (upload.state) {
                case "waiting":
                  return <p key={upload.id}>Esperando para adjuntar</p>;
                case "uploading":
                  return <p key={upload.id}>Adjuntando {upload.progress}%</p>;
                case "error":
                  return (
                    <p key={upload.id}>
                      Error al adjuntar archivo {upload.error}
                    </p>
                  );
                case "finished":
                  return <p key={upload.id}>Archivo adjunto</p>;
              }
            })}
          </div>
        )}
      />
    </div>
  );
};
export default Adjuntos;
