import React, { useState } from "react";
import LightBox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const VerImagen = ({ open, onClose, fotos }) => {
  const [index, setIndex] = useState(0);

  if (open)
    return (
      <LightBox
        nextSrc={fotos[index + 1]}
        prevSrc={fotos[index - 1]}
        mainSrc={fotos[index]}
        onCloseRequest={onClose}
        onMovePrevRequest={() =>
          setIndex((index + fotos.length - 1) % fotos.length)
        }
        onMoveNextRequest={() => setIndex((index + 1) % fotos.length)}
      />
    );
  return null;
};

export default VerImagen;
