import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { es } from "date-fns/locale";
registerLocale("es", es);
setDefaultLocale("es");

export const MonthPicker = ({ onChange, value }) => {
  return (
    <DatePicker
      selected={value}
      onChange={date => onChange(date)}
      className="input"
      placeholderText="Periodo"
      dateFormat="MM/yyyy"
      showMonthYearPicker
    />
  );
};

export const DateTimePicker = ({ onChange, value }) => {
  return (
    <DatePicker
      selected={value ? new Date(value) : null}
      className="input"
      onChange={date => onChange(date)}
      showTimeSelect
      timeFormat="HH:mm"
      timeCaption="Hora"
      dateFormat="dd/MM/yyyy HH:mm"
    />
  );
}