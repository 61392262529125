import { connect } from "react-redux";
import Home from "./Index";

const mapStateToProps = state => {
  return {
    usuario: state.data.currentUsuario
  };
};

const mapDispatchToProps = _dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
