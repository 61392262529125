import React, { useState, useEffect } from "react";

const Index = ({ usuario }) => {
  const [height, setHeight] = useState("");
  useEffect(() => {
    if (height === "") {
      setHeight(document.body.scrollHeight + "px");
    }
  });
  return (
    <div>
      <h1 className="is-title is-2">{usuario.reporte_name}</h1>
      <div className="container reportes">
        {height !== "" && (
          <iframe
            width="100%"
            style={{ height: height }}
            src={usuario.reporte_url}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
