import React from "react";
import { difHoras, difHorasConFormato } from "../../lib/fechas";
import classNames from "classnames";

const Duracion = ({ orden }) => {
  const duracion = difHoras(orden.created_at, orden.finished_at);
  const classes = classNames("tag", {
    "is-success": duracion < 1,
    "is-warning": duracion >= 1 && duracion < 2,
    "is-danger": duracion >= 2
  });

  const duracionString = difHorasConFormato(
    orden.created_at,
    orden.finished_at
  );
  return <span className={classes}>{duracionString}</span>;
};

export default Duracion;
