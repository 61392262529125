import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { ZoomIn, AddLocation } from "@material-ui/icons";
import BatteryLevel from "./BatteryLevel";
import { timeAgo, difHoras } from "../../lib/fechas";
import Chip from "@material-ui/core/Chip";
import missing from "images/missing.png";
import Tooltip from "@material-ui/core/Tooltip";
import { trackUser } from "../../lib/api";
import classNames from "classnames";
import Img from "react-image";

const pickColor = lastSeen => {
  var diff = difHoras(lastSeen);
  var color = "primary";
  if (diff > 1) {
    if (diff < 24) {
      color = "warning";
    } else {
      color = "default";
    }
  }
  return color;
};

const colorNameToClass = colorName => {
  let className = "border-grey-700";
  switch (colorName) {
    case "default":
      className = "border-red-600";
      break;
    case "warning":
      className = "border-yellow-300";
      break;
    case "primary":
      className = "border-blue-500";
      break;
  }
  return className;
};

const Version = ({ version, lastSeen }) => {
  return (
    <Tooltip title={timeAgo(lastSeen)}>
      <Chip label={version} color={pickColor(lastSeen)} />
    </Tooltip>
  );
};

const Ubicacion = ({ usuario, onZoomClick, onChange }) => {
  const [tracking, setTracking] = useState(false);
  const onTrack = () => {
    setTracking(true);
    trackUser(usuario.id);
    setTimeout(() => {
      setTracking(false);
      onChange();
    }, 5000);
  };
  const color = pickColor(usuario.last_time);
  const boxClasses = classNames(
    "box",
    "p-2",
    "border-l-8",
    colorNameToClass(color)
  );
  return (
    <div className={boxClasses}>
      <article className="media">
        <div className="media-left">
          <figure className="image is-64x64 overflow-hidden rounded-lg">
            <Img src={[usuario.thumbnail_url, missing]} alt="Image" />
          </figure>
        </div>
        <div className="media-content">
          <div className="content">
            <strong>{usuario.nombre}</strong>
            <br />
            <Version
              version={usuario.last_version}
              lastSeen={usuario.last_time}
            />
          </div>
          <nav className="level is-mobile">
            <div className="level-left">
              <a className="level-item" aria-label="reply">
                <Button onClick={onTrack} disabled={tracking}>
                  <AddLocation />
                </Button>
                <Button
                  onClick={() => onZoomClick(usuario)}
                  disabled={usuario.last_lat === -1}
                >
                  <ZoomIn />
                </Button>

                <Tooltip
                  title={`${usuario.last_battery.toFixed(2)}%`}
                  placement="top"
                >
                  <Button>
                    <BatteryLevel battery={usuario.last_battery} />
                  </Button>
                </Tooltip>
              </a>
            </div>
          </nav>
        </div>
      </article>
    </div>
  );
};

export default Ubicacion;
