import React, { useState, useEffect } from "react";
import VerImagen from "./ver-foto";
import loadImage from "blueimp-load-image/js";

const getDataUrl = img => {
  // Create canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // Set width and height
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw the image
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL("image/jpeg");
};

const Camera = ({ foto, adjuntos }) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);

  const addImage = url => {
    loadImage(
      url,
      img => {
        if (img.type === "error") {
          console.log("Error cargando imagen", url);
        } else {
          var base64data = getDataUrl(img);
          setImage(base64data);
        }
      },
      { orientation: true, crossOrigin: "Anonymous", contain: true }
    );
  };

  useEffect(() => {
    if (foto.foto_url) {
      addImage(foto.foto_url);
    }
  }, [adjuntos]);

  return (
    <>
      <figure
        className="image is-128x128 rounded-lg overflow-hidden m-4"
        style={{ cursor: "pointer" }}
      >
        <figure className="image is-square" style={{ cursor: "pointer" }}>
          <img src={image} onClick={() => setOpen(true)} />
        </figure>
      </figure>
      <VerImagen open={open} foto={image} onClose={() => setOpen(false)} />
    </>
  );
};

export default Camera;
