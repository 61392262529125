import React from "react";
import AsyncSelect from "react-select/async";
import { loadLugares } from "../../lib/api";

const LugaresSelect = ({ onChange, defaultValue }) => {
  const loadOptions = async (inputValue, callback) => {
    const lugares = await loadLugares(inputValue);
    const options = lugares.data.map(lugar => ({
      label: lugar.nombre + " - " + lugar.id,
      id: lugar.id,
      value: lugar.id
    }));
    callback(options);
  };

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      defaultValue={defaultValue}
      placeholder="Buscar..."
      onChange={onChange}
      defaultOptions
      isClearable
    />
  );
};

export default LugaresSelect;
