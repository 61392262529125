import { combineReducers } from "redux";
import LugaresReducer from "./reducer_lugares";
import DataReducer from "./reducer_data";
import OrdenesReducer from "./reducer_ordenes";
import TrabajosReducer from "./reducer_trabajos";

const rootReducer = combineReducers({
  lugares: LugaresReducer,
  data: DataReducer,
  ordenes: OrdenesReducer,
  trabajos: TrabajosReducer
});

export default rootReducer;
