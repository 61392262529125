/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import ReactDOM from "react-dom";
import React from "react";
import MainApp from "../src/MainApp";

document.addEventListener("DOMContentLoaded", () => {
  const mountPoint = document.querySelector("#react-mount");
  if (mountPoint === null) return;
  const usuario = JSON.parse(mountPoint.dataset.currentUsuario).data.attributes;
  const urls = JSON.parse(mountPoint.dataset.urls);
  ReactDOM.render(<MainApp usuario={usuario} urls={urls} />, mountPoint);
});
