import React from "react";

const FormField = props => (
  <div className="my-1">
    {props.label && (
      <label htmlFor={props.label.toLowerCase()} className="mb-1 font-bold">
        {props.label}
      </label>
    )}
    <div className="control" style={{ minHeight: 40 }}>
      {props.children}
    </div>
  </div>
);

export default FormField;
