import { createSelector } from "reselect";

export const getUsuarios = state => state.data.usuarios;

export const getUsuariosConGeo = createSelector(
  [getUsuarios],
  usuarios => usuarios.filter(usuario => usuario.last_lat !== null)
);

export const getUsuariosForSelect = createSelector(
  [getUsuarios],
  usuarios =>
    usuarios.map(usuario => ({
      label: usuario.nombre,
      id: usuario.id,
      value: usuario.id
    }))
);
