import React, { useState, useEffect } from "react";
import FormField from "../common/FormField";
import VerImagen from "./ver-foto";
import loadImage from "blueimp-load-image/js";
import update from "immutability-helper";
import { isNullOrEmpty } from "../../lib/utils";

const getDataUrl = img => {
  // Create canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // Set width and height
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw the image
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL("image/jpeg");
};

const Camera = ({ respuesta }) => {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const isEmpty = respuesta.fotos
    ? respuesta.fotos.length === 0
    : isNullOrEmpty(respuesta.valor_url);

  const addImage = url => {
    loadImage(
      url,
      img => {
        if (img.type === "error") {
          console.log("Error cargando imagen", url);
        } else {
          var base64data = getDataUrl(img);
          setImages(actual => update(actual, { $push: [base64data] }));
        }
      },
      { orientation: true, crossOrigin: "Anonymous", contain: true }
    );
  };

  useEffect(() => {
    if (!respuesta.fotos) {
      if (respuesta.valor_url) {
        addImage(respuesta.valor_url);
      }
    } else {
      respuesta.fotos.forEach(foto => {
        if (foto.foto_url) {
          addImage(foto.foto_url);
        }
      });
    }
  }, [respuesta]);

  return (
    <FormField label={respuesta.descripcion}>
      {respuesta.fotos && (
        <span className="tag is-info">{respuesta.fotos.length} fotos</span>
      )}
      {isEmpty && <span>Sin Imagen</span>}
      {!isEmpty && images.length === 0 && <span>Cargando...</span>}
      {images.length > 0 && (
        <>
          <figure
            className="image is-128x128 rounded-lg overflow-hidden"
            style={{ cursor: "pointer" }}
          >
            <figure className="image is-square" style={{ cursor: "pointer" }}>
              <img src={images[0]} onClick={() => setOpen(true)} />
            </figure>
          </figure>
          <VerImagen
            open={open}
            fotos={images}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </FormField>
  );
};

export default Camera;
