import React from "react";
import {
  BatteryAlert,
  Battery30,
  Battery60,
  Battery90,
  BatteryUnknown,
  BatteryFull
} from "@material-ui/icons";

const BatteryLevel = ({ battery }) => {
  var level = <BatteryUnknown />;
  if (battery == 100) {
    level = <BatteryFull color="primary" />;
  }
  if (battery >= 90 && battery < 100) {
    level = <Battery90 />;
  }

  if (battery >= 50 && battery < 90) {
    level = <Battery60 />;
  }

  if (battery >= 10 && battery < 50) {
    level = <Battery30 color="error" />;
  }

  if (battery < 10) {
    level = <BatteryAlert color="error" />;
  }
  return level;
};

export default BatteryLevel;
