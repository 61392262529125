import React from "react";
import LightBox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const VerImagen = ({ open, onClose, foto }) => {
  if (open) return <LightBox mainSrc={foto} onCloseRequest={onClose} />;
  return null;
};

export default VerImagen;
