import React from "react";

const IconButton = ({ onClick, icon, children }) => (
  <a className="button" onClick={onClick}>
    <span className="icon is-small">
      <i className={`fa fa-${icon}`} />
    </span>
    <span>{children}</span>
  </a>
);

export default IconButton;
