import axios from "axios";
import Kitsu from "kitsu";
import { deserialise } from "kitsu-core";
import csrfToken from "./csrfToken";
import { format } from "date-fns";
var fileDownload = require("js-file-download");

const api = new Kitsu({
  baseURL: "/api/v1",
  headers: {
    "X-CSRF-Token": csrfToken()
  }
});

let csrf = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers["X-CSRF-TOKEN"] = csrf;

export function trackUser(user_id) {
  return axios.post(`/api/v2/usuarios/${user_id}/track`);
}

export async function loadLugares(q) {
  return api.get("lugares", {
    filter: {
      q,
    },
  });
}

export function loadUsuarios() {
  return api.fetch("operadores/?page[size]=100");
}

export function loadTrabajos() {
  return api.fetch("trabajos/?include=acciones");
}

export function loadOrdenes(filtros, page) {
  let url = `ordenes/?include=lugar,trabajo,usuario&page[number]=${page}`;
  url = `${url}&fields[ordenes]=numero,finished_at,created_at,estado,lugar,trabajo,usuario`;
  url = `${url}&fields[lugares]=nombre`;
  url = `${url}&fields[usuarios]=nombre`;
  if (filtros && filtros.periodo) {
    const periodo = format(filtros.periodo, "MM-yyyy");
    url = `${url}&filter[periodo]=${periodo}`;
  }
  if (filtros && filtros.usuario_id) {
    url = `${url}&filter[usuario_id]=${filtros.usuario_id}`;
  }
  if (filtros && filtros.trabajo_id) {
    url = `${url}&filter[trabajo_id]=${filtros.trabajo_id}`;
  }
  if (filtros && filtros.lugar_id) {
    url = `${url}&filter[lugar_id]=${filtros.lugar_id}`;
  }
  if (filtros && filtros.estado) {
    url = `${url}&filter[estado]=${filtros.estado}`;
  }
  if (filtros && filtros.nro_orden) {
    url = `${url}&filter[numero]=${filtros.nro_orden}`;
  }
  return api.fetch(url);
}

export async function getOrden(id) {
  const response = await api.fetch(
    `ordenes/${id}?include=lugar,trabajo,usuario`
  );
  const data = await deserialise(response);
  return data;
}

export async function crearOrden(orden) {
  if (typeof orden.adjuntos === "undefined") {
    delete orden.adjuntos;
  }
  const response = await api.create("ordenes", orden);
  const data = await deserialise(response);
  return data;
}

export async function editarOrden(cambios) {
  const response = await api.update("ordenes", cambios);
  const data = await deserialise(response);
  return data;
}

export async function excelOrdenes(query) {
  let periodo = null;
  if (query && query.periodo) {
    periodo = format(query.periodo, "MM-yyyy");
  }
  const url = `api/v1/exportar.xlsx?`;
  const response = await axios.get(url, {
    responseType: "blob",
    params: {
      id: query.nro_orden,
      estado: query.estado,
      trabajo_id: query.trabajo_id,
      lugar_id: query.lugar_id,
      usuario_id: query.usuario_id,
      periodo
    }
  });
  fileDownload(response.data, `ordenes.xlsx`);
}

export async function logout() {
  return await axios.delete("/usuarios/sign_out.json");
}

export async function deleteOrdenAttach(attach_id) {
  const response = await axios.post("api/v1/ordenes/delete_attach_file", {
    attach_id
  });
  return response.data;
}

export async function getURL(blob_key) {
  const response = await axios.post("api/v1/ordenes/get_url", {
    blob_key
  });
  return response.data;
}

export async function purgeOrdenAttachment(signed_id) {
  const response = await axios.post(`api/v1/ordenes/purge_attachment`, {
    signed_id
  });
  return response.data;
}
