import { createSelector } from "reselect";

export const getTrabajos = state => state.trabajos.trabajos;

export const getTrabajosForSelect = createSelector(
  [getTrabajos],
  trabajos =>
    trabajos.map(trabajo => ({
      label: trabajo.nombre,
      value: trabajo.id,
      ...trabajo
    }))
);
