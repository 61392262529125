import React, { useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import Modal from "react-modal";
import FormField from "../common/FormField";
import { connect } from "react-redux";
import { getUsuariosForSelect } from "../../store/selectors/usuarios";
import {
  updateOrden,
  fetchOrdenes
} from "../../store/actions/ordenes";


const AsignarOrden = ({
  updateOrden,
  usuarios,
  saving,
  orden,
  getOrdenes
}) => {
  const [operador, setOperador] = useState(null);
  const [modal, setModal] = useState(false);

  const Guardar = async e => {
    e.preventDefault();
    await updateOrden({
      id: orden.id,
      usuario_id: operador,
      estado: "creado"
    });
    setModal(false);
    await getOrdenes();
  };

  const guardarClass = classNames({
    button: true,
    "is-primary": true,
    "is-loading": saving
  });

  const customStyles = {
    content: {
      top: "20%",
      left: "15%",
      right: "15%", 
      bottom: "20%"
    }
  };

  return (
    <div>
      <button className="button is-primary" onClick={() => setModal(true)}>
        Asignar
      </button>
      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <h1 className="title">Asignar Operador</h1>
        <hr className="navbar-divider" />
        <form>
          <div className="columns">
            <div className="column">
              <FormField label="Operador">
                <Select
                  options={usuarios}
                  onChange={e => setOperador(e.id)}
                />
              </FormField>
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button
                className={guardarClass}
                onClick={Guardar}
                disabled={saving || !operador}
              >
                {saving ? "Guardando..." : "Guardar"}
              </button>
              {setModal && (
                <button className="button" onClick={() => setModal(false)}>
                  Cerrar
              </button>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    usuarios: getUsuariosForSelect(state),
    permisos: state.data.currentUsuario.permisos,
    saving: state.ordenes.saving
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrden: cambios => dispatch(updateOrden(cambios)),
    getOrdenes: () => dispatch(fetchOrdenes())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsignarOrden);
